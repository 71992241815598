import React from 'react';
import styles from './index.module.scss';
import {useInView} from "react-intersection-observer";

const Footer = () => {
  const [ref, inView] = useInView({
    triggerOnce: true, // 仅触发一次
  });

  return (
    <div ref={ref} className={styles.footer_page}>
      <div className={styles.top_wrapper}>
        <div className={`${styles.left_wrapper} ${inView && 'left-enter-an'}`}>
          <div className={styles.title}>Tiger Network is the fairest blockchain network</div>
        </div>
        <div className={`${styles.right_wrapper} ${inView && 'right-enter-an'}`}>
          <div className={styles.list_wrapper}>
            <div className={styles.title}>SOCIAL</div>
            <a href={'https://t.me/aicowcoin'} className={styles.list_item}>Telegram</a>
            <a href={'https://x.com/Jack1142587?t=1HAFyuUaND8IaqZxxDuibQ&s=09'}  className={styles.list_item}>Twitter</a>
            <a href={'https://www.facebook.com/profile.php?id=61554309178277&mibextid=kFxxJD'}  className={styles.list_item}>Facebook</a>
            <a href={'https://wa.me/qr/6CSPPN5RSTQVK1'}  className={styles.list_item}>WhatsApp</a>
          </div>
          {/* <div className={styles.list_wrapper}>*/}
          {/*  <div className={styles.title}>RESOURCES</div>*/}
          {/*  <a className={styles.list_item}>Knowledge Base</a>*/}
          {/*  <a className={styles.list_item}>Whitepaper</a>*/}
          {/*  <a className={styles.list_item}>FAQ</a>*/}
          {/*  <a className={styles.list_item}>GitHub</a>*/}
          {/*  <a style={{*/}
          {/*    opacity: 0,*/}
          {/*    visibility: 'hidden'*/}
          {/*  }} className={styles.list_item}>{'sfs '}</a>*/}
          {/*  <div style={{marginBottom: '7px'}} className={styles.title}>CONTACT</div>*/}
          {/*  <a className={styles.list_item}>dsad.com</a>*/}
          {/* </div>*/}
        </div>
      </div>
      <div className={`${styles.bottom_wrapper} ${inView && 'gradient-an'}`}>
        <div className={styles.com_text}>2023 © Tiger Network Labs. All Rights Reserved.</div>
        <div className={styles.agreement_wrapper}>
          <a className={styles.text}>Terms</a>
          <div className={styles.line}></div>
          <a className={styles.text}>Privacy</a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
