import React from 'react';
import {Drawer} from "antd";
import styles from  './index.module.scss';
import {useRouter} from "next/router";


const MobileDrawer = ({ vis, onClose }) => {

  const router = useRouter();

  const onTabClick = (key) => {
    if (key === 1) {
      router.push('/');
    } else if (key === 2) {
      router.push('/white_paper');
    }
    onClose?.();
  };

  return (
    <Drawer className={styles.drawer_sty} title={" "} placement={"right"} onClose={onClose} visible={vis}>
      <div className={styles.drawer_content}>
        <div className={styles.tab_wrapper}>
          <div onClick={() => onTabClick(1)} className={`${styles.tab_item} ${router.pathname === '/' && styles.tab_item_active}`}>Home</div>
          {/* <div onClick={() => onTabClick(2)} className={`${styles.tab_item} ${router.pathname === '/white_paper' && styles.tab_item_active}`}>White Paper</div>*/}
        </div>
        <div className={styles.language_wrapper}>

          <div className={styles.icon}></div>
          <div className={styles.text}>EN</div>

        </div>
      </div>
    </Drawer>
  );

};

export default MobileDrawer;
