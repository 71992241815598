import Link from 'next/link';
import getConfig from 'next/config';
import {Button, Popover} from 'antd';
import {LoginOutlined, EditOutlined, DownOutlined} from '@ant-design/icons';
import { color_white } from '@/constants/CustomTheme';
import {useRouter} from "next/router";
import MobileDrawer from "@/components/MobileDrawer";
import {useState} from "react";
import LanguagePopover from "@/components/LanguagePopover";

// Only holds serverRuntimeConfig and publicRuntimeConfig from next.config.js nothing else.
const { publicRuntimeConfig: { staticFolder } } = getConfig();

const Header = ({ isScrollingUp, scrollTop }) => {

  const router = useRouter();
  const [drawerVis, setDrawerVis] = useState(false);

  const onDrawerClose = () => {
    setDrawerVis(false);
  };

  return (
    (
      <div id='header_bar'
           style={{
             backgroundColor: router.pathname === '/white_paper' ? 'rgba(255, 255, 255, .2)' : scrollTop < 50 ? 'rgba(255, 255, 255, 0)' : 'rgba(255, 255, 255, 0.5)',
             top: scrollTop < 50 ? 0 : isScrollingUp ? 0 : '-84px',
             backdropFilter: (isScrollingUp || router.pathname === '/white_paper') ? 'blur(4px)' : 'blur(0px)'
           }}
           className={`header-bar-container ${(isScrollingUp || router.pathname === '/white_paper') && 'ios-backdrop-filter'}`}
      >
        <MobileDrawer vis={drawerVis} onClose={onDrawerClose} />
        <Link href='/'>
          <div className='logo-container'>
            <img className='logo' alt='logo' src={`${staticFolder}/logo.png`} />
            <a className='sys-name'>Tiger Network</a>
          </div>
        </Link>
        <div className='right-container'>
          <div className={'header-tab-wrapper'}>
            <div onClick={() => router.push('/')} className={`header-tab-item ${router.pathname === '/' && 'header-tab-item-active'}`}>
              <span>Home</span>
              <div className={'header-tab-item-line'}></div>
            </div>
            {/* <div onClick={() => router.push('/white_paper')} className={`header-tab-item ${router.pathname === '/white_paper' && 'header-tab-item-active'}`}>*/}
            {/*  <span>White Paper</span>*/}
            {/*  <div className={'header-tab-item-line'}></div>*/}
            {/* </div>*/}
          </div>
          <LanguagePopover />
          {/* <Button onClick={() => router.push('/register')} className={'login_btn'} type={'primary'}>Register</Button>*/}
          <div onClick={() => setDrawerVis(true)} className={'drawer_icon_wrapper'}>
            <div className={'line-1'}></div>
            <div className={'line-2'}></div>
            <div className={'line-3'}></div>
          </div>
        </div>
      </div>
    )
  );
};

export default Header;
