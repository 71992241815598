import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import DynamicAntdTheme from 'dynamic-antd-theme';
import Header from './Header';
import Footer from "@/components/Footer";
function Layout({ children }) {

  const [scrolling, setScrolling] = useState(false);
  const [isScrollingUp, setIsScrollingUp] = useState(true);
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollTop(window.scrollY);
      setScrolling(true);
      if (window.scrollY < scrollTop) {
        setIsScrollingUp(true);
      } else {
        setIsScrollingUp(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollTop]);


  return (
    <>
      <style jsx>{`
        .content-container {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          //margin: 70px 20px 20px 20px;
          //padding: 10px 20px;
          //height: 2000px;
          background-color: #fff;
        }
      `}</style>
      <Header isScrollingUp={isScrollingUp} scrollTop={scrollTop} />
      <div className='content-container'>
        {children}
      </div>
      <Footer />
    </>
  );
}
export default Layout;
Layout.propTypes = {
  children: PropTypes.any
};

Layout.defaultProps = {
  children: null
};
