import React from 'react';
import styles from './index.module.scss';
import {Popover} from "antd";
import {DownOutlined} from "@ant-design/icons";

const LanguagePopover = () => {

  const content = (
    <div className={styles.language_pop_wrapper}>

    </div>
  );

  return (
    <Popover overlayClassName={styles.popover_sty} content={content} title={"Title"} placement={"bottomLeft"} trigger={"hover"}>
      <div className={styles.language_item}>
        <div className={styles.icon}></div>
        <div className={styles.text_wrapper}>
          <div className={styles.text}>EN</div>
          <div className={styles.arr_down_icon}><DownOutlined /></div>
        </div>
      </div>
    </Popover>
  );
};

export default LanguagePopover;
